<template>
  <div class="container mt-5">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <div class="row bortr mt-2">
    <div class="col-sm-12">
      <h1 class="text-center">Tax Invoice / فاتورة ضريبية</h1>
    </div>
    </div>
    <div class="row bortr">
      <div class="col-sm-6 p-0">
      <table class="table table-sm m-0">
      <tbody>
        <tr class="no-border">
          <td>SES/ESV No.</td>
          <td>
            {{ invoice.ses_esv }}
          </td>
          <td class="text-right">رقم أرامكو المرجعي</td>

          <td>Vendor no.</td>
          <td>{{ invoice.vendor }}</td>
          <td class="text-right">رقم المزود</td>
        </tr>

        <tr class="no-border">
          <td>Invoice No:</td>
          <td>{{ invoice.invoice_id }}</td>
          <td class="text-right">رقم الفاتورة</td>

          <td>Contract No.</td>
          <td>{{ invoice.contract_no }}</td>
          <td class="text-right">رقم العقد</td>
        </tr>
        <tr class="no-border">
          <td>Invoice Issue Date:</td>
          <td>{{ invoice.created_at | moment("DD MMM YYYY") }}</td>
          <td class="text-right">تاريخ الفاتورة</td>
          <td>Rel PO. No.</td>
          <td>{{ invoice.po }}</td>
          <td class="text-right">رقم امر الخدمة</td>
        </tr>

        <tr class="no-border">
          <td>Invoice Period:</td>
          <td>
            {{ invoice.invoice_start | moment("DD MMM YYYY") }} to
            {{ invoice.invoice_end | moment("DD MMM YYYY") }}
          </td>
          <td class="text-right">مــدة الفاتورة</td>
        </tr>
       
        <tr class="no-border">
          <td>Reference No</td>
          <td>{{ invoice.ref_invoice }}</td>
          <td class="text-right">رقم المرجع</td>
        </tr>
        <tr class="no-border">
          <td>Cost Center</td>
          <td>{{ invoice.costcenter }}</td>
          <td class="text-right">مركز الدفع</td>
        </tr>
        <tr class="no-border">
          <td>Invoice Type</td>
          <td>{{ invoice.invoice_type }}</td>
          <td class="text-right">نوع الفاتورة</td>
        </tr>

        <tr class="no-border">
          <td>Regional</td>
          <td>{{hc_address}}</td>
          <td class="text-right">المنطقة</td>
        </tr>
      </tbody>
    </table>
      </div>
      <div class="col-sm-6">
            <div class="mt-4 float-right">
              <vue-qrcode
                :value="invoice.qrcode"
                :options="{ width: 200 }"
              ></vue-qrcode>
            </div>
      </div>
    </div>
    <div class="row">
      <table class="table table-sm m-0">
      <tbody>
        <tr class="no-border top-boder">
          <td class="text-left">Seller</td>
          <td class="text-center"></td>
          <td class="text-right td-3">: البائع</td>
          <td class="text-left">Buyer</td>
          <td class="text-center"></td>
          <td class="text-right">:المشتري</td>
        </tr>

        <tr class="no-border">
          <td class="text-left">Company Name</td>
          <td class="text-center fa-nb">
            شركة ابناء عبدالرحمن على البصيلى
            <br>
            AbdulRahman Ali AlBusaili Sons Co
          </td>
          <td class="text-right td-3">اسم الشركة</td>
          <td class="text-left">Company Name</td>
          <td class="text-center fa-nb">
            شركة الزيت السعودية العربية - ارامكو
            <br>
            SAUDI ARABIAN OIL CO. (ARAMCO)  
          </td>
          <td class="text-right">اسم الشركة</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">VAT ID</td>
          <td class="text-center fa-nb">300047805100003</td>
          <td class="text-right td-3">رقم شهادة ضريبة القيمة المضافة</td>
          <td class="text-left">VAT ID</td>
          <td class="text-center fa-nb">300000432310003</td>
          <td class="text-right">رقم شهادة ضريبة القيمة المضافة</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Building No</td>
          <td class="text-center fa-nb">7529</td>
          <td class="text-right td-3">رقم المبني</td>
          <td class="text-left">Building No</td>
          <td class="text-center fa-nb">4297</td>
          <td class="text-right">رقم المبني</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Street Name</td>
          <td class="text-center fa-nb">Salah Aldin Al-Ayoubi/صلاح الدين الايوبي</td>
          <td class="text-right td-3">إسم الشارع</td>
          <td class="text-left">Street Name</td>
          <td class="text-center fa-nb">East-District/الجادة الشرقية</td>
          <td class="text-right">إسم الشارع</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">District</td>
          <td class="text-center fa-nb">Al-Zahraa /الزهراء</td>
          <td class="text-right td-3">الحي</td>
          <td class="text-left">District</td>
          <td class="text-center fa-nb"> W-Dhahran/حي غرب الظهران  </td>
          <td class="text-right">الحي</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">City</td>
          <td class="text-center fa-nb">Riyadh /الرياض</td>
          <td class="text-right td-3">المدينة</td>
          <td class="text-left">City</td>
          <td class="text-center fa-nb">Dhahran / ‫الظهران‬</td>
          <td class="text-right">المدينة</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Country</td>
          <td class="text-center fa-nb">KSA /السعودية</td>
          <td class="text-right td-3">الدولة</td>
          <td class="text-left">Country</td>
          <td class="text-center fa-nb">KSA /المملكة العربية السعودية</td>
          <td class="text-right">الدولة</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Postal Code</td>
          <td class="text-center fa-nb">12811</td>
          <td class="text-right td-3">الرمزي البريدي</td>
          <td class="text-left">Postal Code</td>
          <td class="text-center fa-nb">34465</td>
          <td class="text-right">الرمزي البريدي</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Additional No.</td>
          <td class="text-center fa-nb">2071</td>
          <td class="text-right td-3">الرقم الأضافي </td>
          <td class="text-left">Additional No.</td>
          <td class="text-center fa-nb">8091</td>
          <td class="text-right">الرقم الأضافي </td>
        </tr>
        <tr class="no-border">
          <td class="text-left">CR Number</td>
          <td class="text-center fa-nb">1010152299</td>
          <td class="text-right td-3">رقم السجل التجاري</td>
          <td class="text-left">CR Number.</td>
          <td class="text-center fa-nb">2052101150</td>
          <td class="text-right">رقم السجل التجاري</td>
        </tr>
        <tr class="no-border top-boder">
          <td class="text-left" colspan="3">Line Items:</td>
          <td class="text-right" colspan="3">البند</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="row">
    <table class="table table-sm m-0">
      <tbody>
        <tr class="row-tr">
          <td class="text-center">Description الوصف</td>
          <td class="text-center">Unit Price / سعر الوحدة</td>
          <td class="text-center">Quantity/الكمية</td>
          <td class="text-center">Taxable Amount/القيمة</td>
          <td class="text-center">Discount/الخصم</td>
          <td class="text-center">Tax Rate/نسبة الضريبة</td>
          <td class="text-center">Tax Amount/قيمة الضريبة</td>
          <td class="text-center">
            Item Subtotal Including VAT/ المبلغ بعد الضريبة
          </td>
        </tr>
        <tr class="row-tr" v-for="(value, index) in items" :key="index">
          <td class="text-left fa-nb">
            {{ value.item }} / {{ value.item_arabic }}
          </td>
          <td class="text-center fa-nb">{{ comma(value.price) }}</td>
          <td class="text-center fa-nb">{{ value.quantity }}</td>
          <td class="text-center fa-nb">{{ comma(value.amount) }}</td>
          <td class="text-center fa-nb">0</td>
          <td class="text-center fa-nb">15%</td>
          <td class="text-center fa-nb">{{ comma(value.amount * 0.15) }}</td>
          <td class="text-center fa-nb">{{ sum(value.amount) }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="row bortr mt-4">
      <div class="col-sm-6  p-0">
        <p class="text-left fa-bold m-1 f-14"> Total Amount</p>
      </div>
      <div class="col-sm-6 p-0">
        <p class="text-right fa-bold m-1  f-14"> المبلغ الإجمالي</p> 
      </div>
    </div>
    <div class="row bortr">
      <div class="col-sm-6 p-0">
      </div>
      <div class="col-sm-6 p-0">
      <table class="table table-sm mr-0">
      <tbody>
        <tr class="no-border">
          
          <td class="text-left">Sub Total</td>
          <td class="text-right">الإجمالي قبل الضريبة</td>
          <td class="text-right">{{ invoice_total_amount }}</td>
        </tr>
        <tr class="no-border">
          
          <td class="text-left">Discount</td>
          <td class="text-right">الخصم</td>
          <td class="text-right">0</td>
        </tr>
        <tr class="no-border">
          
          <td class="text-left">Total Taxable Amount (Excluding VAT)</td>
          <td class="text-right">الاجمالي قبل الضريبة</td>
          <td class="text-right">{{ invoice_total_amount }}</td>
        </tr>
        <tr class="no-border">
          
          <td class="text-left">Total VAT</td>
          <td class="text-right">قيمة الضريبة</td>
          <td class="text-right">{{ invoice_vat_amount }}</td>
        </tr>
        <tr class="no-border">
          
          <td class="text-left">Gross Amount</td>
          <td class="text-right">القيمة</td>
          <td class="text-right">{{ invoice_gross_amount }}</td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Retention 10%</td>
          <td class="text-right">قيمة الاستقطاع</td>
          <td class="text-right">
            {{ invoice_rentention_amount }}
          </td>
        </tr>
        <tr class="no-border">
          <td class="text-left">Total Amount Due</td>
          <td class="text-right">   المبلغ الإجمالي</td>
          <td class="text-right">{{ invoice_net_amount }}</td>
        </tr>
      </tbody>
    </table>
      </div>
    </div>
    <div class="row">    
    <table class="table table-sm no-border">
      <tbody>
        <tr>
          <td class="text-left">Amount in Words: {{ invoice.amount_words }}</td>
          <td class="text-right"> المبلغ بالكلمات:{{ invoice.amount_words_ar }}</td>
        </tr>        
      </tbody>
    </table>
    </div>
    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoice",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_net_amount: 0,
      // amount_words: "",
      hc_address: "Yanbu",
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    sum(n1) {
      let vat = Number(n1) * 0.15;
      let total = Number(n1) + vat;
      return this.comma(total);
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount =  this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.word {
  text-transform: capitalize;
}
.fa-bold {
  font-weight: bold;
}
.float-center {
  text-align: center;
}
.table tr td {
  font-size: 14px !important;
  font-weight: bold;
  margin: 0;
  padding: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.bortr {
  border: black solid 1px;
}
.no-border {
  border-left: black solid 1px;
  border-right: black solid 1px;
}
.right-border {
  border-right: black solid 1px !important;
}
.top-boder {
  border-top: solid 1px black !important;
}
.bottom-boder {
  border-bottom: solid 1px black !important;
}
.table td {
  border: solid 1px black;
}
.td-3 {
  border-right: solid 1px black !important;
}
.row-tr td {
  border: black solid 1px;
}
.fa-nb {
  font-weight: 300 !important;
}
 .f-14{
font-size: 14px !important;
 }
.image {
  width: 100px;
  align-self: center;
}
.header-arabic {
  align-self: flex-end;
  font-size: 15px;
  width: 300px;
  border: 1px solid #000;
}
.al-right {
  align-self: flex-end;
}
.df {
  display: flex;
  align-items: center;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-sm {
  font-size: 10px;
}
.text-md {
  font-size: 14px;
}
</style>
<!-- <template>
  <div class="container">
       <div class="row">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <h2 class="mt-3 text-center border p-2"><b>INVOICE </b></h2>
    <div class="row mt-4">
      <div class="col-sm-6 col-md-6">
        <h3>
          <b>REL.P.O. No: {{ invoice.po }} </b>
        </h3>
        <h3>
          <b>Vendor No: {{ invoice.vendor }} </b>
        </h3>
        <h3>
          <b><u>VAT Reg.No: 300047805100003 </u> </b>
        </h3>
        <h4>Authorizes you to pay</h4>
        <h3>Bank Name : {{ invoice.bank }}</h3>
        <h3>Branch No. 297, Ahssa St., City: Riyadh</h3>
        <h3>Bank Account No: 9920002219940</h3>
        <h3>Name: AbdulRahman Ali AlBusaili Sons Co.</h3>
        <h3>TO :</h3>
        <h3>
          <b>{{ invoice.customer }} </b>
        </h3>
        <h3>Invoice Control Unit,</h3>
        <h3>Box # 1500, Dhahran – 31311 </h3>
        <h3>Saudia Arabia</h3>
        <h3>
          <b><u>VAT Reg. No: 300000432310003 </u> </b>
        </h3>
      </div>
      <div class="col-sm-6 col-md-6">
        <h3>SES/ESV No: {{ invoice.ses_esv }}</h3>
        <h3>Invoice No: {{ invoice.invoice_id }}</h3>
        <h3>Invoice Date: {{ invoice.created_at | moment("DD MMMM YYYY") }}</h3>
        <h3>
          Invoice Period: {{ invoice.invoice_start | moment("DD MMMM YYYY") }} -
          {{ invoice.invoice_end | moment("DD MMMM YYYY") }}
        </h3>
        <h3>REF No: {{ invoice.ref_invoice }}</h3>
        <h3>Cost Center: {{ invoice.costcenter }}</h3>
        <h3>Invoice Type: {{ invoice.invoice_type }}</h3>
        <h3>Reg. ERDD J GP Dep Approval: Yes</h3>
        <div class="float-right">
          <vue-qrcode
            :value="invoice.qrcode"
            :options="{ width: 250 }"
          ></vue-qrcode>
        </div>
      </div>
    </div>

    <div class="row border mt-2 pt-3">
      <div class="col-sm-6 col-md-6 col-xs-6 col-xxl-6">
        <h3>
          <b>Contract No: {{ invoice.contract_no }} </b>
        </h3>
        <h3>
          <b>Contractor No: {{ invoice.contractor_no }} </b>
        </h3>
      </div>
      <div class="col-sm-6 col-md-6 col-xs-6 col-xxl-6">
        <h3>
          <b
            >Contract Started Date:
            <span class="pl-1">
              {{ invoice.contract_start | moment("MM/DD/YYYY") }}
            </span>
          </b>
        </h3>
        <h3>
          <b
            >Contract End Date:
            <span class="pl-5">
              {{ invoice.contract_end | moment("MM/DD/YYYY") }}
            </span>
          </b>
        </h3>
      </div>
      <div class="col-sm-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="float-center">Item</th>
              <th class="float-center">Quantity</th>
              <th class="float-center">Price</th>
              <th class="float-center">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in items" :key="index">
              <td>{{ value.item }}</td>
              <td>
                <p class="float-center">{{ value.quantity }}</p>
              </td>
              <td>
                <p class="float-right">{{ comma(value.price) }}</p>
              </td>
              <td>
                <p class="float-right">{{ comma(value.amount) }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-12 mt-5">
        <table class="line-height table table-borderless">
          <tr>
            <td class="w-75">
              <span> RPO Amount</span>
            </td>
            <td class="w-10">
              <span class="float-right pl-3 pt-1">SR</span>
            </td>
            <td class="w-20 float-right">
              <span class="ml-4">{{ invoice_total_amount }}</span>
            </td>
          </tr>
          <tr>
            <td class="w-75"><span>Add: VAT 15%</span></td>
            <td><span class="float-right pl-3 pt-1">SR</span></td>
            <td class="w-20 float-right">
              <span class="ml-4"> {{ invoice_vat_amount }}</span>
            </td>
          </tr>
          <tr>
            <td class="w-75">
              <span> <b>Gross Amount </b> </span>
            </td>
            <td class="w-10"><span class="float-right pl-3 pt-1">SR</span></td>
            <td class="float-right border-top">
              <span class="ml-4">
                <b> {{ invoice_gross_amount }} </b></span
              >
            </td>
          </tr>
          <tr>
            <td class="w-75">
              <span>Less: 10% Retention</span>
            </td>
            <td class="w-10"><span class="float-right pl-3 pt-1">SR</span></td>
            <td class="w-20 float-right">
              <span class="ml-4"> {{ invoice_rentention_amount }}</span>
            </td>
          </tr>
          <tr>
            <td class="w-75">
              <span> <b>Net Amount Payable </b></span>
            </td>
            <td class="w-10"><span class="float-right pl-3 pt-1">SR</span></td>
            <td class="w-20 float-right border-top border-bottom">
              <span class="ml-4">
                <b> {{ invoice_net_amount }} </b></span
              >
            </td>
          </tr>
        </table>
      </div>
      <div class="col-sm-12">
        <h4 class="word">Amount in words: {{ invoice.amount_words }}</h4>
        <br />
        <h4>Yours Truly</h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <p style="width: 200px; border-top: solid 2px"></p>
        <h3><b> MR. SULAIMAN ALBUSAILI </b></h3>
        <h4>Project Executive Director</h4>
      </div>
    </div>
    <h3 class="text-center mt-2 mb-2">PROPONENT APPROVAL</h3>
    <div class="row">
      <table class="border table table-bordered">
        <tr>
          <td>
            <div class="row">
              <div class="col-md-6">
                <h3>GI. Account / Cost Element:</h3>
              </div>
              <div class="col-md-6 fs-row">
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
              </div>
            </div>
          </td>
          <td>
            <h3>Verified By:</h3>
          </td>
          <td>
            <h3>Tel:</h3>
          </td>
        </tr>
        <tr>
          <td>
            <h3>Plus one of the following (1,2,3 or 4)</h3>
          </td>
          <td>
            <h3>Approved By:</h3>
          </td>
          <td>
            <h3>Badge No.</h3>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-md-6">
                <h3>1. Cost Center:</h3>
              </div>
              <div class="col-md-6 fs-row">
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
              </div>
            </div>
          </td>
          <td><h3>Position:</h3></td>
          <td><h3>Tel:</h3></td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-md-6">
                <h3>2. WBS:</h3>
              </div>
              <div class="col-md-6 fs-row">
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
                <div class="box"></div>
              </div>
            </div>
          </td>
          <td><h3>Signature:</h3></td>
          <td><h3>Date:</h3></td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-md-6">
                <h3>3. Pant MTC Order:</h3>
                <div class="fs-row">
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                </div>
              </div>
              <div class="col-md-6">
                <h3>4. Internal Order:</h3>
                <div class="fs-row">
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                  <div class="box"></div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <h3>Approve Gross Amount</h3>
          </td>
          <td>
            <h3>
              <b>SR {{ invoice_gross_amount }} </b>
            </h3>
          </td>
        </tr>
      </table>
    </div>

    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoice",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_net_amount: 0,
      // amount_words: "",
      hc_address: "",
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];

          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);
          this.invoice_rentention_amount = this.comma(
            this.invoice.retention_amount
          );
          this.invoice_net_amount = this.comma(this.invoice.net_amount);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.fs-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.center {
  width: 1100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border {
  border: solid 2px black !important;
}
.word {
  text-transform: capitalize;
}
.fa-bold {
  font-weight: bold;
}

.float-center {
  text-align: center;
}

.box {
  width: 20px;
  height: 25px;
  border: 1px solid black;
}
.line-height {
  line-height: 0.5px;
}

.border-top {
  border-top: solid 1px black !important;
}

.border-bottom {
  border-bottom: 3px double black !important;
}

.table th {
  font-size: 20px !important;
}

.table td {
  margin: 4px;
  font-size: 18px !important;
}
</style> -->